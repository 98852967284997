<template>
    <div>
        <banner :banner-name="articlesDetail.articles_title"
                :background-img="articlesDetail.articles_image_url">
            <div slot-scope="slot" :style="{backgroundImage:'url('+slot.backgroundImg+')' }"
                 class="banner an-blog-InDown"> <!-- animate__animated animate__fadeInDown-->
                <div class="article-banner">
                    <div class="article-info-container">
                        <div class="article-info-title">{{slot.bannerName}}</div>
                        <div class="article-info">
                            <div class="first-line">
                                <span><v-icon size="10">mdi-timetable</v-icon> <span
                                        class="mr-1">发表于</span>{{articlesDetail.create_time}}</span> <span
                                    class="separator">|</span> <span> <v-icon>mdi-update</v-icon>
                                <span class="mr-1">更新于</span>  {{articlesDetail.update_time}}</span>
                                <span class="separator">|</span>
                                <router-link tag="span" :to="'/categories/'+articlesDetail.categorie.id">
                                    <v-icon>mdi-format-list-bulleted</v-icon>
                                    {{articlesDetail.categorie.categorie_name}}
                                </router-link>
                            </div>
                            <div class="second-line"><span> <v-icon>mdi-format-font</v-icon><span
                                    class="mr-1">字数统计:</span>{{articlesDetail.word_count}}</span> <span
                                    class="separator">|</span>
                                <span> <v-icon>mdi-timer</v-icon><span class="mr-1">阅读时长:</span>{{articlesDetail.reading_time}}分钟</span>
                            </div>
                            <div class="third-line"><span class="separator">|</span><span> <v-icon>mdi-eye</v-icon><span
                                    class="mr-1">阅读量:</span>{{articlesDetail.reading_quantity}}</span>
                                <span
                                        class="separator">|</span><span> <v-icon>mdi-message-draw</v-icon><span
                                        class="mr-1">评论数:</span>{{articlesDetail.comment_count}}</span>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </banner>

        <v-row class="articles-container an-blog-articles-InUp">
            <!--文章内容-->
            <v-col md="9" cols="12">
                <v-card class="article-body markdown-body">
                    <article v-html="articleContent" ref="article" class="article-content"></article>
                    <div class="aritcle-copyright">
                        <div v-show="isOriginal">
                            <span>文章作者：</span> <a href="#">{{$store.getters.blogInfo.username}}</a>
                        </div>
                        <div>
                            <span>文章链接：</span>
                            <a v-if="isOriginal" href="#">{{currentUrl}}</a>
                            <a v-else target="_blank" :href="articlesDetail.articles_reprint_url">{{articlesDetail.articles_reprint_url}}</a>

                        </div>
                        <div>
                            <span>版权声明：</span>本博客所有文章除特别声明外，均采用 <a
                                href="https://creativecommons.org/licenses/by-nc-sa/4.0/"> CC
                            BY-NC-SA 4.0</a> 许可协议。转载请注明文章出处。
                        </div>
                    </div>
                    <!--标签/分享按钮-->
                    <div class="article-operation">
                        <div class="tag-container">
                            <a :href="'/tags/'+tag.id" :key="tag.id" v-for=" tag in articlesDetail.tags">{{tag.tag_name}}</a>
                        </div>
                        <share class="share-container" :config="config"/>
                    </div>


                    <div class="link-reward">
                        <div class="articles-reward" @click="reward_dialog = true">
                            <v-icon size="25" color="#eeeeee">mdi-qrcode</v-icon>
                            <span class="ml-1">打赏</span></div>
                    </div>
                    <!--上下篇文章-->
                    <div class="pagination-post">
                        <router-link tag="div" :to="'/articles/'+updown.pre_data.data.id"
                                     v-if="updown.pre_data.existe"
                                     class="post">
                            <img class="post-cover"
                                 :src="updown.pre_data.data.articles_image_url"
                                 alt="">
                            <div class="post-info">
                                <div>上一篇</div>
                                <div>{{updown.pre_data.data.articles_title}}</div>
                            </div>
                        </router-link>
                        <router-link tag="div" :to="'/articles/'+updown.next_data.data.id"
                                     v-if="updown.next_data.existe"
                                     class="post">
                            <img class="post-cover"
                                 :src="updown.next_data.data.articles_image_url"
                                 alt="">
                            <div class="post-info post-next">
                                <div>下一篇</div>
                                <div> {{updown.next_data.data.articles_title}}</div>
                            </div>
                        </router-link>
                    </div>
                    <!--相关推荐-->
                    <div class="recommend-container">
                        <div class="recommend-title">
                            <v-icon size="20" color="#4c4948">mdi-thumb-up</v-icon>
                            相关推荐
                        </div>
                        <div class="recommend-list">
                            <router-link tag="div" :to="'/articles/'+recommend.id " :key="recommend.id"
                                         v-for="recommend in articlesRecommendData.recommends"
                                         class="post recommend-item">
                                <img class="post-cover"
                                     :src="recommend.articles_image_url"
                                     alt="">
                                <div class="post-info recommend-info">
                                    <div class="recommend-date">
                                        <v-icon>mdi-calendar-month-outline</v-icon>
                                        <span>{{recommend.create_time}}</span>
                                    </div>
                                    <div>{{ recommend.articles_title }}</div>
                                </div>
                            </router-link>
                        </div>
                    </div>
                    <div class="split-line"></div>
                    <!--评论-->
                    <div class="comment-container">
                        <div class="recommend-title">
                            <v-icon size="20" color="#4c4948">mdi-comment</v-icon>
                            评论
                        </div>
                        <comment
                                @commentsSuccess="commentArticlesSuccess"
                                :comments_type="comments_type"
                                :article_id="articlesDetail.id"/>
                    </div>
                    <!--评论内容-->
                    <div class="comment-content-container">
                        <div class="recommend-title">
                            <v-icon size="20" color="#4c4948">mdi-message-reply-text</v-icon>
                            {{comments_count}}条评论
                        </div>
                        <comment-list
                                @nextPageData="nextArticlesPageData"
                                @nextChildrenPageData='nextArticlesChildrenPageData'
                                :comments_type="comments_type"
                                :comments-page-info="articlesCommentsData.pageInfo"
                                :comment-data='articlesCommentsData.data'
                                :articles_id="articlesDetail.id"/>
                    </div>
                </v-card>

            </v-col>

            <!--左侧边栏-->
            <v-col md="3" cols="12">

                <div class="container-right">
                    <!--文章目录-->
                    <v-card class="articles-catalogue d-md-block d-none animate__animated animate__zoomIn">
                        <div class="right-title">
                            <v-icon>mdi-menu</v-icon>
                            <span style="margin-left:10px">目录</span>
                        </div>
                        <div id="toc"/>
                    </v-card>

                    <v-card class="newest-articles mt-5 d-md-block d-none animate__animated animate__zoomIn">
                        <div class="right-title">
                            <v-icon>mdi-clock-time-eight-outline</v-icon>
                            <span style="margin-left:10px">最新文章</span>
                        </div>
                        <div class="articles-list">
                            <router-link :key="newest.id" v-for="newest in articlesRecommendData.newests"
                                         :to="'/articles/'+newest.id" tag="div" class="articles-list-item"
                                         :style="{backgroundImage:'url(' + newest.articles_image_url + ')'}">
                                <div class="newest-articles-content mr-1 ml-1">
                                    <p>{{newest.articles_title}}</p>
                                    <span>{{newest.create_time}}</span>
                                </div>
                            </router-link>
                        </div>
                    </v-card>

                </div>

            </v-col>
        </v-row>
        <v-dialog
                v-model="reward_dialog"
                width="450"
        >
            <v-card>
                <v-card-title class="text-h5 lighten-2 reward-title justify-center">
                    感谢您的打赏
                </v-card-title>
                <div class="reward-switch">
                    <span @click="reward_config.is_zfb = false"
                          :class="{'reward-switch-activate':reward_config.is_zfb === false}">微信</span>
                    <span @click="reward_config.is_zfb = true"
                          :class="{'reward-switch-activate':reward_config.is_zfb === true}">支付宝</span>
                </div>
                <div class="reward-qrCode">
                    <v-img max-width="300" max-height="300"
                           :src=" reward_config.is_zfb?reward_config.reward_rqcode.zhifubao:reward_config.reward_rqcode.weixin"></v-img>
                </div>
            </v-card>
        </v-dialog>


    </div>
</template>

<script>


  import Banner from "@/components/banner/Banner";
  import Comment from '@/components/comment/comment'
  import CommentList from '@/components/comment/commentList'
  import tocbot from "tocbot"
  import {getArticlesDetailsApi, getArticlesRecommendApi, getCommentsListApi} from "@/network/interface"
  import {CommentsDataMixin} from "@/components/CommentMixin"

  export default {
    name: "Articles",
    mixins: [CommentsDataMixin],
    data() {
      return {
        reward_dialog: false, // 打赏激活
        reward_config: {
          is_zfb: false,
          reward_rqcode: {
            zhifubao: 'https://boke-dashang.obs.cn-southwest-2.myhuaweicloud.com/0f5f30af01688df63150ace78d14b45.jpg',
            weixin: 'https://boke-dashang.obs.cn-southwest-2.myhuaweicloud.com/fb5721228f55f540bc22e266287bfdc.jpg'
          }
        },
        config: {
          sites: ["qzone", "wechat", "weibo", "qq"]
        },
        articleContent: "",
        articlesDetail: {
          id: 0,
          articles_title: '',
          tags: [],
          categorie: {}
        },
        articlesRecommendData: {
          newests: [],
          recommends: [],
          updown: {
            next_data: {existe: false, data: {id: ''}},
            pre_data: {existe: false, data: {id: ''}}
          }
        },
        articlesCommentsData: {
          pageInfo: {
            pageNum: 1,
            total: 0,
            totalPages: 1,
            comments_count: 1,
          },
          data: [],
        },
        comments_type: 0,
        imgList: [],
      }
    },
    computed: {
      /*当前路径*/
      currentUrl() {
        return window.location.href
      },
      /*是否为原创*/
      isOriginal() {
        return parseInt(this.articlesDetail.articles_type) === 0
      },
      updown() {
        return this.articlesRecommendData.updown
      },
      comments_count() {
        return this.articlesCommentsData.pageInfo.comments_count
      }
    },
    created() {
      if (this.isNumber(this.$route.params.id)) {
        this.getArticleDetails({id: this.$route.params.id})
        this.getArticlesRecommend({id: this.$route.params.id})
        // 获取评论数据
        this.getCommentsList({comments_type: this.comments_type, article_id: this.$route.params.id})

      } else {
        this.$router.push('/')
      }
    },
    destroyed() {
      tocbot.destroy()
    },
    methods: {
      /*是否是数字*/
      isNumber(value) {
        const regPos = /^\d+?$/
        if (regPos.test(value)) {
          return true
        } else {
          return false
        }
      },
      // 获取文章详情
      getArticleDetails(params) {
        getArticlesDetailsApi(params).then(resp => {
          this.articlesDetail = resp.data
          this.getArticleContent(resp.data.articles_content)
        }).catch(err => {
        })
      },
      // 获取文章推荐数据
      getArticlesRecommend(params) {
        getArticlesRecommendApi(params).then(resp => {
          this.articlesRecommendData = resp.data
        }).catch(err => {
        })
      },
      // 获取下一页文章评论数据
      nextArticlesPageData() {
        const params = {
          comments_type: this.comments_type,
          article_id: this.$route.params.id,
          pageNum: this.articlesCommentsData.pageInfo.pageNum + 1
        }
        this.nextPageData(params)
      },
      // 获取下一页子评论数据
      nextArticlesChildrenPageData(father_id, pageNum) {
        this.nextChildrenPageData(father_id, pageNum, this.comments_type, {})
      },
      // 文章评论成功
      commentArticlesSuccess(data) {
        this.getCommentsList({comments_type: this.comments_type, article_id: this.$route.params.id})
      },
      previewImg(img) {
        this.$imagePreview({
          images: this.imgList,
          index: this.imgList.indexOf(img),
        });
      },
      getArticleContent(data) {
        let md = require('markdown-it')();
        this.articleContent = md.render(data);
        setTimeout(() => {
          this.getArticleCatalogue()
        }, 1000)
      },
      getArticleCatalogue() {
        const that = this
        // 添加文章生成目录功能
        let nodes = this.$refs.article.children;
        if (nodes.length) {
          for (let i = 0; i < nodes.length; i++) {
            let node = nodes[i];
            let reg = /^H[1-4]{1}$/;
            if (reg.exec(node.tagName)) {
              node.id = i;
            }
          }
        }
        tocbot.init({
          tocSelector: "#toc", //要把目录添加元素位置，支持选择器
          contentSelector: ".article-content", //获取html的元素
          headingSelector: "h1, h2, h3,h4", //要显示的id的目录
          hasInnerContainers: true,
          onClick: function (e) {
            e.preventDefault();
          }
        });

        // 坑:需要等图片引用后才行
        setTimeout(() => {
          //添加图片观看
          // 添加图片预览功能
          const imgList = this.$refs.article.getElementsByTagName("img");
          for (var i = 0; i < imgList.length; i++) {
            this.imgList.push(imgList[i].src);
            imgList[i].addEventListener("click", function (e) {
              that.previewImg(e.target.currentSrc);
            });
          }
        }, 1000)
      }
    },
    components: {
      Banner,
      Comment,
      CommentList
    }
  }
</script>

<style scoped>


    .article-banner {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.5);

    }


    .article-info-container {
        position: absolute;
        top: 45%;
        text-align: center;
        line-height: 1.9;
        width: 100%;
        color: #eeeeee;
    }


    .article-info {
        font-size: 0.85rem;
    }

    .separator {
        margin: 0 5px;
    }

    .article-info-title {
        font-size: 2.25rem;

    }

    .second-line, .third-line {
        display: inline-block;
    }

    .article-info .v-icon {
        font-size: 0.85rem !important;
        color: #eeeeee;

    }


    .articles-container {
        max-width: 1200px;
        margin: 410px auto 0 auto;
        height: 100%;
        padding-top: 65px;
    }


    .article-body {
        padding: 50px 40px;
    }


    /*复制文章说明*/
    .aritcle-copyright {
        position: relative;
        margin-top: 40px;
        margin-bottom: 10px;
        font-size: .875rem;
        line-height: 2;
        padding: .625rem 1rem;
        border: 1px solid #eee;
    }

    .aritcle-copyright:after {
        position: absolute;
        top: .95rem;
        right: .95rem;
        width: .5rem;
        height: .5rem;
        border-radius: .5em;
        background: #fff;
        content: "";
    }

    .aritcle-copyright:before {
        position: absolute;
        top: .7rem;
        right: .7rem;
        width: 1rem;
        height: 1rem;
        border-radius: 1rem;
        background: #49b1f5;
        content: "";
    }

    .aritcle-copyright span {
        color: #56b7f6;
        font-weight: 700;
    }

    .aritcle-copyright a {
        color: #a1afc4 !important;
        text-decoration: underline;
    }


    /*标签*/
    .article-operation {
        display: flex;
        justify-content: space-between;
    }

    .tag-container a {
        display: inline-block;
        margin: .5rem .5rem .5rem 0;
        padding: 0 .75rem;
        width: -moz-fit-content;
        width: fit-content;
        border: 1px solid #49b1f5;
        border-radius: 1rem;
        color: #49b1f5 !important;
        font-size: 12px;
        line-height: 2;
        transition: all 1s;
    }

    .tag-container a:hover {
        color: #eeeeee !important;
        text-decoration: none;
        background-color: #49b1f5 !important;
    }

    .link-reward {
        margin-top: 30px;
        display: flex;
        justify-content: center

    }

    .link-reward .articles-reward {
        color: #eeeeee;
        display: flex;
        align-items: center;
        background-color: #03a9f4;
        padding: 5px 30px;
    }

    .reward-title {

        background-color: #1a0907;
        color: #e1e1e1;
    }

    .reward-switch {
        display: flex;
    }

    .reward-switch span {
        flex: 1;
        padding: 10px 0;
        background-color: #a0a5a4;
        text-align: center;
        font-size: 1.3rem;
        color: #eeeeee;
    }

    .reward-switch-activate {
        background-color: #00c4b6 !important;
    }



    .reward-qrCode {
        display: flex;
        justify-content: center;
        padding: 30px;

    }

    #toc {
        white-space: nowrap; /*文字显示不开,强制一行*/
        overflow: hidden; /*溢出的部分隐藏*/
        text-overflow: ellipsis; /*文字用省略号代替*/
    }

    /*上下篇文章*/
    .pagination-post {
        display: flex;
        margin-top: 40px;
    }

    .post {
        position: relative;
        width: 100%;
        height: 150px;
        overflow: hidden;
        background: #000;
        transition: all 1s;
    }

    .post-cover {
        width: 100%;
        height: 100%;
        opacity: .4;
        object-fit: cover;
        transition: all 1s;
    }

    .post:hover {
        background: #ffffff;
    }

    .post:hover .post-cover {
        transform: scale(1.1);
        opacity: 1;
    }

    .post-next {
        text-align: right;
    }

    .post-info {
        position: absolute;
        padding: 20px 40px;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        color: #eeeeee;
        z-index: 1;
    }


    /*相关推荐*/
    .recommend-container {
        margin-top: 20px;
    }

    .recommend-title {
        font-size: 20px;
        line-height: 2;
        font-weight: 700;
        margin-bottom: 20px;
    }

    .recommend-list {
        display: flex;
        flex-wrap: wrap;

    }

    .recommend-item {
        margin: 3px;
        line-height: 2;
        height: 200px;
        width: calc(33.333% - 6px);
    }

    .recommend-info {
        text-align: center;
    }

    .recommend-info div:nth-child(2) {
        font-size: 14px;
    }

    .recommend-date {
        font-size: 12px;
    }

    .recommend-date .v-icon {
        color: #eeeeee;
        font-size: 14px;
    }

    /*文章目录*/
    .container-right {
        position: sticky !important;
        top: 10px !important;
    }

    .articles-catalogue, .newest-articles {
        padding: 20px 24px;
        font-size: 14px;
    }


    .right-title {
        display: flex;
        align-items: center;
        line-height: 2;
        font-size: 16.8px;
        margin-bottom: 6px;
    }


    /* 最新文章 */
    .articles-list-item {
        display: flex;
        justify-content: center;
        min-height: 80px;
        overflow: hidden;
        position: relative;
        background-size: cover;
        line-height: 1.5;
        align-items: center;
        margin: 15px 0;
        transition: 1s all;
    }

    .articles-list-item:before {
        z-index: 1;
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #000;
        opacity: 0.5;
    }

    .articles-list-item:hover {
        transform: scale(1.1);
    }

    .newest-articles-content {
        z-index: 2;
    }

    .newest-articles-content {
        text-align: center;
        overflow: hidden;
        color: #eeeeee;
        font-size: 95%;
        margin: 0;
    }

    .articles-list-item p:first-child {
        margin-bottom: 0;
        font-size: 1.1rem;
        overflow: hidden;
        text-overflow: ellipsis;
        /* 弹性伸缩盒子模型显示 */
        display: -webkit-box;
        /* 限制在一个块元素显示的文本的行数 */
        -webkit-line-clamp: 2;
        /* 设置或检索伸缩盒对象的子元素的排列方式 */
        -webkit-box-orient: vertical;
    }


    .articles-list-item span {
        display: inline-block;
        font-size: 0.8rem;
        transition: 1s all;
    }


    /*评论*/
    .comment-container {

    }

    .comment-content-container {
        margin-top: 40px;
    }


    .split-line {
        border-top: 2px dashed #d2ebfd;
        border-bottom: 2px dashed #d2ebfd;
        margin: 20px 0;

    }

    @media screen and (max-width: 750px) {
        .banner {
            height: 360px;
        }

        .article-info-container {
            padding: 0 20px;
            top: auto;
            bottom: 10%;
            text-align: left;
        }

        .article-body {
            padding: 50px 35px;
        }

        .article-info-title {
            font-size: 1.6rem;

        }

        .second-line, .third-line {
            display: block;
        }

        .third-line .separator:nth-child(1) {
            display: none;
        }

        .article-info {
            font-size: 0.8rem;
        }

        .article-info .v-icon {
            font-size: 0.8rem !important;
        }

        .articles-container {
            padding-top: 10px;
        }

        .article-operation {
            flex-direction: column
        }

        .tag-container {
            display: flex;
        }

        .share-container {
            display: none;
        }

        .tag-container a {
            margin-left: 0.3rem;
        }

        /*post文章*/
        .pagination-post {
            flex-direction: column;
        }

        .recommend-item {
            width: 100%;
        }

    }


    @media screen and (min-width: 1900px) {
        .articles-container {
            margin-top: 450px;
        }
    }
</style>
